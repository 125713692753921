import * as React from "react";
import { MainLayout } from "../layouts/MainLayout";

// markup
const NotFoundPage = () => {
  return (
    <MainLayout>
      <section className="section">
        <main className="container">
          <h2 className="title">NOT FOUND</h2>
        </main>
      </section>
    </MainLayout>
  );
};

export default NotFoundPage;